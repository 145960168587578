<template>
    <div class="add-customers-popup">
        <div
            class="add-customers-popup__input"
            :class="{
                'is-all-selected': isSelectedAll,
                'custom-selection-label': true,
                'is-disabled': isDisabled,
            }"
        >
            <div class="cursor-pointer position-relative z-index-1">
                <div
                    v-if="!isDisabled"
                    @click="handleOpenModal"
                    class="select-popup"
                ></div>

                <div class="is-all-selected__text" v-if="isSelectedAll">{{ selectHeadingAllSelectedText }}</div>
                <div class="custom-selection-label__text" v-else>{{ getCustomPlaceholder }}</div>

                <el-select
                    :multiple="multiple"
                    collapse-tags
                    v-model="inputValue"
                    :disabled="isDisabled"
                >
                    <el-option
                        v-for="(item, index) in options"
                        :key="index"
                        :label="(item.name || item.title)"
                        :value="item.id"
                    ></el-option>
                </el-select>
            </div>
        </div>

        <SelectionModalTable
            v-if="dialogVisible"
            :dialog-visible="dialogVisible"
            :options="options"
            :modal-title="modalTitle"
            :modal-subtitle="modalSubtitle"
            :additional-columns="additionalColumns || customersTableColumns"
            :modelValue="modelValue"
            :show-logo="showLogo"
            @handleCloseModal="handleCloseModal"
            @handleConfirm="handleConfirm"
        />
    </div>
</template>

<script>
import CustomPagination from "@/buying-teams/shared-components/utils/CustomPagination.vue";
import SelectionModalTable from "@/buying-teams/shared-components/settings/SelectionModalTable.vue";

export default {
    name: "CustomersBanksSelectBox",
    components: {
        SelectionModalTable,
        CustomPagination
    },
    props: {
        modelValue: {
            type: [Array, String],
            required: true
        },
        multiple: {
            type: Boolean,
            default: false
        },
        options: {
            type: Array,
            default: [],
            required: true
        },
        isDisabled: {
            type: Boolean,
            default: false
        },
        placeholder: String,
        allSelectedText: String,
        modalTitle: String,
        modalSubtitle: String,
        additionalColumns: {
            type: Array,
            default: null,
        },
        isForBankPlatform: {
            type: Boolean,
            default: false,
        },
        showLogo: {
            type: Boolean,
            default: true
        },
    },
    emits: ["onTouch", "update:modelValue"],
    data() {
        return {
            inputValue: null,
            dialogVisible: false,
        }
    },
    computed: {
        customersTableColumns() {
            return [
                {
                    prop: 'revenue',
                    label: this.$t('Revenue'),
                    minWidth: '100px'
                },
                {
                    prop: 'industries',
                    label: this.$t('Industry'),
                    minWidth: '150px',
                    isMultiple: true,
                },
                {
                    prop: 'country',
                    label: this.$t('Country'),
                    minWidth: '100px'
                }
            ]
        },
        isSelectedAll() {
            if (!this.multiple) return false;
            return this.modelValue.length === this.options.length && this.options.length !== 0;
        },
        selectHeadingAllSelectedText() {
            const value = this.options[0];
            return this.isSelectedAll && this.options.length === 1
                ? (value.name || value.title)
                : this.allSelectedText ? this.allSelectedText : this.$t("All Selected")
        },
        getCustomPlaceholder() {
            if (this.modelValue.length) {
                const currentElement = this.modelValue[0];
                const titleNotFullSelected = this.isForBankPlatform ? this.$t('Banks') : this.$t('Customers');
                let value = this.options.find((val) => val === currentElement || val.id === currentElement);
                return this.modelValue.length === 1 ? (value.name || value.title) : `${this.modelValue.length} ${ titleNotFullSelected }`;
            } else {
                return this.placeholder ? this.placeholder : this.$t("Select");
            }
        },
    },
    created() {
        if (this.modelValue && this.modelValue.length) {
            this.inputValue = [...this.modelValue];
        }
    },
    methods: {
        handleConfirm(values) {
            this.inputValue = values;
            this.$emit("update:modelValue", values);
            this.$emit("onTouch");
            this.dialogVisible = false;
        },
        handleCloseModal() {
            this.dialogVisible = false;
        },
        handleOpenModal() {
            this.dialogVisible = true;
        }
    },
    watch: {
        modelValue: {
            deep: true,
            handler() {
                if (this.modelValue && this.modelValue.length) {
                    this.inputValue = [...this.modelValue];
                }
            }
        },
    }
}
</script>

<style lang="scss">
.add-customers-popup {
    &__input {
        .custom-selection-label__text,
        .is-all-selected__text {
            display: none;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            padding-left: 22px;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: #434343;
            cursor: pointer;
            z-index: 1;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            left: 0;
            right: 0;
            padding-right: 40px;
        }

        &.is-disabled {
            * {
                cursor: not-allowed;
                color: #b8b8b8 !important;
            }
            .el-input__inner::placeholder {
                color: #b8b8b8 !important;
            }
        }

        &.custom-selection-label {
            .custom-selection-label__text {
                display: block;
            }
        }

        &.is-all-selected {
            .is-all-selected__text {
                display: block;
            }
        }

        &.custom-selection-label,
        &.is-all-selected {
            &.custom-selection-label {
                .el-input__inner::placeholder {
                    color: transparent;
                }
            }
        }

        .el-select {
            width: 100%;

            .el-input {
                width: 100%;

                .el-input__inner {
                    width: 100%;
                    padding-left: 22px;

                    &::placeholder {
                        color: transparent;
                    }
                }

                .el-input__suffix {
                    right: 10px;
                }

                &.is-disabled {
                    .el-input__inner {
                        background: #F6F6F6;
                    }
                }
            }
        }

        .el-select__tags {
            display: none;
        }
    }
}
</style>
