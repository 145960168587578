<template>
    <el-dialog
      v-model="dialogVisible"
      width="980px"
      center
      :show-close="true"
      @close="handleCloseModal"
      append-to-body
      custom-class="add-customers-modal"
    >
        <div class="add-customers-modal__header">
            <div class="add-customers-modal__header--title">
                <h4>{{ modalTitle }}</h4>
                <span v-if="selectedData.length">{{ selectedData.length }} {{ $t('Selected') }}</span>
            </div>
            <p class="add-customers-modal__header--text">
                {{ modalSubtitle }}
            </p>
            <div class="add-customers-modal__header--search" v-if="options.length">
                <inline-svg src="/media/buying/icons/search-black.svg" />
                <input type="text" :placeholder="$t('Search')" v-model="search">
            </div>
        </div>

        <div class="add-customers-modal__table" v-if="options && options.length">
            <el-checkbox class="select-all" size="large" v-model="allSelected" @change="handleChangedAll" />
            <el-table :data="displayData" @row-click="handleChange">
                <el-table-column v-slot="{ row }" prop="checked" width="64">
                    <div
                      class="custom-checkbox"
                      :class="{'is-checked': selectedData.includes(row.id) }"
                    ></div>
                </el-table-column>
                <el-table-column
                    v-slot="{ row }"
                    prop="name"
                    :label="$t('Name')"
                    min-width="200"
                    class-name="user-name"
                >
                    <div class="">
                        <img :src="row.icon_path" :alt="row.name" class="me-4">
                        <span>{{ row.name }}</span>
                    </div>
                </el-table-column>
                <el-table-column
                  v-if="additionalColumns.length"
                  v-for="(col, index) in additionalColumns"
                  :key="index"
                  :prop="col.prop"
                  :label="col.label"
                  :min-width="col.minWidth"
                  :width="col.width"
                  v-slot="{ row, column }"
                >
                    <template v-if="col.isMultiple && row[column.property]">
                        <div class="table-row-ellipsis">{{ row[column.property].join(', ') }}</div>
                    </template>
                    <template v-else>
                        {{ row[column.property] }}
                    </template>
                </el-table-column>
            </el-table>

            <CustomPagination
              class="mt-4 mb-4 ms-7"
              :current-page="page"
              :total="options.length"
              :page-size="pageSize"
              :hide-on-single-page="false"
              @currentPageChange="page = $event"
              @pageSizeChange="pageSize = $event"
            />
        </div>

        <div v-else class="add-customers-modal__table--nodata">
            {{ $t('No Data') }}
        </div>

        <div class="add-customers-modal__footer">
            <button class="main-btn-outline btn" @click="handleCloseModal">{{ $t('Cancel') }}</button>
            <button class="main-btn btn" :disabled="!options.length" @click="handleConfirm">{{ $t('Add') }}</button>
        </div>
    </el-dialog>
</template>

<script>
import CustomPagination from "@/buying-teams/shared-components/utils/CustomPagination.vue";

export default {
    name: "SelectionModalTable",
    components: {
        CustomPagination,
    },
    props: {
        modelValue: {
            type: [Array, String],
            required: true
        },
        dialogVisible: Boolean,
        modalTitle: String,
        modalSubtitle: String,
        additionalColumns: {
            type: Array,
            default: [],
        },
        options: {
            type: Array,
            default: [],
            required: true
        },
    },
    emits: ['handleCloseModal', 'handleConfirm'],
    data() {
        return {
            search: '',
            selectedData: [],
            allSelected: false,
            page: 1,
            pageSize: 5,
        }
    },
    computed: {
        displayData() {
            return this.getFilteredData.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page);
        },
        getFilteredData() {
            let data = [...this.options];
            return this.search ? data.filter((item) => {
                return item.name.toLowerCase().includes(this.search.toLowerCase())
            }) : data;
        },
    },
    methods: {
        handleCloseModal() {
            this.$emit('handleCloseModal');
        },
        handleConfirm() {
            this.$emit('handleConfirm', [...this.selectedData]);
        },
        handleChangedAll(value) {
            if (value) {
                this.selectedData = this.options.map(c => c.id);
            } else {
                this.selectedData = [];
            }
        },
        handleChange(row) {
            if (this.selectedData.includes(row.id)) {
                this.selectedData = this.selectedData.filter(c => c !== row.id);
            } else {
                this.selectedData.push(row.id);
            }
        },
    },
    created() {
        if (this.modelValue && this.modelValue.length) {
            this.selectedData = [...this.modelValue];
        }
    },
    watch: {
        selectedData: {
            deep: true,
            handler(newVal = []) {
                if (newVal.length === this.options.length) {
                    this.allSelected = true;
                } else {
                    this.allSelected = false;
                }
            }
        }
    }
}
</script>

<style lang="scss">
.add-customers-modal {
    .el-dialog__header {
        padding: 0;
    }
    .el-dialog__body {
        padding: 0;
    }
    .custom-checkbox {
        width: 20px;
        height: 20px;
        border: 1px solid #ECECEC;
        border-radius: 50%;
        cursor: pointer;
        transition: .3s ease;

        &:hover {
            border-color: #435BF4;
        }

        &.is-checked {
            border-color: #435BF4;
            background-color: #435BF4;
            background-image: url("/media/buying/icons/checked.svg");
            background-repeat: no-repeat;
            background-position: center;
            background-size: 10px;
        }
    }
    &__header {
        padding: 26px 24px 17px 24px;
        &--title {
            display: flex;
            align-items: center;
            gap: 15px;
            min-height: 29px;
            h4 {
                margin-bottom: 0;
                color: #000;
                font-size: 22px;
                font-weight: 600;
                line-height: 27px;
            }
            span {
                border-radius: 50px;
                background: rgba(67, 91, 244, 0.04);
                padding: 5px 20px;
                color: #435BF4;
                font-size: 16px;
                font-weight: 400;
                line-height: 19px;
            }
        }
        &--text {
            color: #8B8B8B;
            font-size: 14px;
            font-weight: 400;
            line-height: 17px;
            margin-top: 16px;
            margin-bottom: 12px;
        }
        &--search {
            width: 100%;
            max-width: 450px;
            position: relative;
            input {
                width: 100%;
                border-radius: 29px;
                background: #FAFAFA;
                height: 50px;
                outline: none;
                border: none;
                padding: 16.55px 14px 16.56px 40px;
                font-size: 14px;
                font-weight: 400;
                line-height: 17px;
                &::placeholder {
                    color: #C0C4CC;
                }
            }
            svg {
                position: absolute;
                left: 15px;
                top: 50%;
                transform: translateY(-50%);
                path {
                    fill: #8B8B8B;
                }
            }
        }
    }
    &__table {
        border-top: 1px solid #EBEEF5;
        position: relative;
        display: flex;
        flex-direction: column;

        &--nodata {
            color: #D4D4D4;
            font-size: 18px;
            font-weight: 700;
            line-height: 22px;
            margin-bottom: 7px;
            text-align: center;
            padding-top: 50px;
            padding-bottom: 20px;
        }

        .el-pagination__sizes {
            display: none !important;
        }

        .select-all {
            position: absolute;
            left: 23px;
            top: 27px;
        }

        .el-table th.el-table__cell {
            padding: 0;
            height: 74px;
            background: #FAFAFA;
        }
        .el-table .el-table__cell {
            padding: 0;
            height: 74px;
        }
        .el-table {
            tr {
                background: #FAFAFA;
            }
            td, th {
                &:first-child .cell {
                    padding-left: 23px;
                }
            }
            td, th {
                &:last-child .cell {
                    padding-right: 32px;
                }
            }
            tbody {
                .el-table__row {
                    cursor: pointer;
                    user-select: none;
                }
            }
        }
        .el-table__body-wrapper {
            tr {
                background: #fff;
            }
        }
        .el-table__header {
            tr th .cell {
                color: #000;
                font-size: 14px;
                font-weight: 400;
                line-height: 17px;
            }
        }
        .el-table td.el-table__cell {
            background: #fff;
            &.user-name div {
                color: #2B2B2B;
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
            }
            div {
                color: #8B8B8B;
                font-size: 14px;
                font-weight: 600;
                line-height: 23px;
                word-break: break-word;

                img {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    object-fit: cover;
                    object-position: center;
                }
            }
        }
        .el-checkbox, .el-checkbox__input, .el-checkbox__inner {
            width: 20px;
            height: 20px;
            border-radius: 50%;
        }
        .el-checkbox__inner::after {
            left: 7px;
            top: 3px;
        }
    }
    &__footer {
        display: flex;
        justify-content: center;
        padding: 28px;
        gap: 20px;

        button {
            min-width: 144px;
            border: 1px solid #435BF4 !important;
            &.main-btn-outline {

            }
        }
    }
    .table-row-ellipsis {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
    }
}

@media (max-width: 992px) {
    .add-customers-modal {
        --el-dialog-width: 99% !important;
    }
}
</style>
